import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { db } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { AuthContext } from '../contexts/AuthContext';

import { LevelContext } from '../contexts/LevelContext';
import './HomePage.css';
import CircuitBoard from './CircuitBoard';
import levels from './Levels.js';

import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import Header from './Header';
import Footer from './Footer';

const chapters = [
    { title: "Chapter 1:", description: "Batteries, resistors and switches", type: 'beginner' },
    { title: "Chapter 2:", description: "Using schematic symbols", type: 'advanced' }
  ];

const HomePage = () => {
  const navigate = useNavigate();
  const { levelCompletion } = useContext(LevelContext);
  const { levelCompletionAdv } = useContext(LevelContext);
  const [selectedBoard, setSelectedBoard] = useState(0); // Add state to toggle between beginner and advanced boards

  const [isSVGLoaded, setIsSVGLoaded] = useState(false); // Track if the SVG is loaded

  const { currentUser } = useContext(AuthContext);
  const [userProgress, setUserProgress] = useState([]);


  const goToLevel = (levelIndex, exerciseIndex = 0) => {
    navigate(`/level/${levelIndex}/exercise/${exerciseIndex}`);
  };

  // // Create a function to update the SVG elements
  // const updateLevelStatus = useCallback(() => {
  //   // Wire color, star updates, etc. for advanced levels
  //   levelCompletionAdv.forEach((isComplete, index) => {
  //     const levelNumber = index + 1;
  //     const strokeColor = isComplete.completed ? "#FFC935" : "#AFE5FF";
  //     const WirestrokeColor = isComplete.completed ? "#FFC300" : "#70A187";
  //     const filterId = isComplete.completed ? "filter-complete" : "filter-incomplete";

  //     const levelGroup = document.querySelector(`#advanced-circuitboard #Level-${levelNumber}`);

  //     if (levelGroup && levelNumber !== 5) {
  //       const rectGroup = levelGroup.querySelector(`g[id^='Rectangle']`);
  //       if (rectGroup) {
  //         rectGroup.setAttribute("filter", `url(#${filterId})`);
  //         const rectElement = rectGroup.querySelector("rect:last-of-type");
  //         if (rectElement) {
  //           rectElement.setAttribute("stroke", strokeColor);
  //         }
  //       }

  //       const num = levelGroup.querySelector(`path[id='${levelNumber}']`);
  //       if (num) {
  //         num.setAttribute("fill", strokeColor);
  //       }

  //       const wirePaths = document.querySelectorAll(`#advanced-circuitboard path[id^='Level-${levelNumber}-']`);
  //       wirePaths.forEach(path => {
  //         path.setAttribute("stroke", WirestrokeColor);
  //       });

  //       const bonusPaths = document.querySelectorAll(`#advanced-circuitboard #Level-${levelNumber}-Bonus path`);
  //       bonusPaths.forEach(path => {
  //         path.setAttribute("stroke", WirestrokeColor);
  //       });

  //       const additionalPaths = document.querySelectorAll(`#advanced-circuitboard [id*='Level-${levelNumber}-']`);
  //       additionalPaths.forEach(path => {
  //         if (path.tagName === 'path') {
  //           path.setAttribute("stroke", WirestrokeColor);
  //         }
  //       });
  //     }

  //     // Show/hide radial gradient for bonus level
  //     const bonusLevelGroup = document.querySelector("#advanced-circuitboard #Bonus-level");
  //     if (bonusLevelGroup) {
  //       const radialGradientCircle = bonusLevelGroup.querySelector("#Ellipse\\ 215 path");
  //       if (radialGradientCircle) {
  //         if (levelCompletionAdv[1].completed && levelCompletionAdv[2].completed && levelCompletionAdv[3].completed) {
  //           radialGradientCircle.style.display = "block";
  //           bonusLevelGroup.classList.add("clickable");
  //           bonusLevelGroup.addEventListener("click", () => goToLevel(4));
  //         } else {
  //           radialGradientCircle.style.display = "none";
  //           bonusLevelGroup.classList.remove("clickable");
  //           bonusLevelGroup.removeEventListener("click", () => goToLevel(4));
  //         }
  //       }
  //     }
  //   });

  //   // Wire color, star updates, etc. for beginner levels
  //   levelCompletion.forEach((isComplete, index) => {
  //     const levelNumber = index + 1;
  //     const strokeColor = isComplete.completed ? "#FFC935" : "#AFE5FF";
  //     const WirestrokeColor = isComplete.completed ? "#FFC300" : "#70A187";
  //     const filterId = isComplete.completed ? "filter-complete" : "filter-incomplete";

  //     const levelGroup = document.querySelector(`#beginner-circuitboard #Level-${levelNumber}`);

  //     if (levelGroup && levelNumber !== 5) {
  //       const rectGroup = levelGroup.querySelector(`g[id^='Rectangle']`);
  //       if (rectGroup) {
  //           const filterExists = document.querySelector(`#${filterId}`);
  //           if (filterExists) {
  //             rectGroup.setAttribute("filter", `url(#${filterId})`);
  //           } else {
  //             console.error(`Filter #${filterId} not found in DOM`);
  //           }
  //         const rectElement = rectGroup.querySelector("rect:last-of-type");
  //         if (rectElement) {
  //           rectElement.setAttribute("stroke", strokeColor);
  //         }
  //       }

  //       const num = levelGroup.querySelector(`path[id='${levelNumber}']`);
  //       if (num) {
  //         num.setAttribute("fill", strokeColor);
  //       }

  //       const wirePaths = document.querySelectorAll(`#beginner-circuitboard path[id^='Level-${levelNumber}-']`);
  //       wirePaths.forEach(path => {
  //         path.setAttribute("stroke", WirestrokeColor);
  //       });

  //       const bonusPaths = document.querySelectorAll(`#beginner-circuitboard #Level-${levelNumber}-Bonus path`);
  //       bonusPaths.forEach(path => {
  //         path.setAttribute("stroke", WirestrokeColor);
  //       });

  //       const additionalPaths = document.querySelectorAll(`#beginner-circuitboard path[id*='Level-${levelNumber}-']`);
  //       additionalPaths.forEach(path => {
  //         if (path.tagName === 'path') {
  //           path.setAttribute("stroke", WirestrokeColor);
  //         }
  //       });
  //     }

  //     const starElement = document.getElementById(`Star-${levelNumber}`);
  //     if (starElement) {
  //       const starFilter = isComplete.starAchieved ? `url(#filter${6 + index}_d_450_270)` : `url(#filter${11 + index}_d_450_270)`;
  //       const starFill = isComplete.starAchieved ? "#FFC300" : "#565656";
  //       const starStroke = isComplete.starAchieved ? "#FFB020" : "#3B3B3B";
  //       const starPath = starElement.querySelector("path:first-of-type");
  //       const starOutline = starElement.querySelector("path:last-of-type");

  //       if (starPath) {
  //         starPath.setAttribute("fill", starFill);
  //       }
  //       if (starOutline) {
  //         starOutline.setAttribute("stroke", starStroke);
  //       }
  //       starElement.setAttribute("filter", starFilter);
  //     }

  //     const bonusLevelGroup = document.querySelector("#beginner-circuitboard #Bonus-Level");
  //     if (bonusLevelGroup) {
  //       const radialGradientCircle = bonusLevelGroup.querySelector("circle");
  //       if (radialGradientCircle) {
  //         if (levelCompletion[0].completed && levelCompletion[1].completed && levelCompletion[2].completed) {
  //           radialGradientCircle.style.display = "block";
  //           bonusLevelGroup.classList.add("clickable");
  //           bonusLevelGroup.addEventListener("click", () => goToLevel(4));
  //         } else {
  //           radialGradientCircle.style.display = "none";
  //           bonusLevelGroup.classList.remove("clickable");
  //           bonusLevelGroup.removeEventListener("click", () => goToLevel(4));
  //         }
  //       }
  //     }
  //   });
  // }, [levelCompletion, levelCompletionAdv, goToLevel,userProgress]);

  const updateLevelStatus = useCallback(() => {
    // Iterate over levels
    levels.forEach((level, levelIdx) => {
      const levelNumber = levelIdx + 1;
      const levelProgress = userProgress[levelIdx] || {};
  
      // Determine if the level is completed and if the star is achieved
      const exercises = level.exercises || [];
      const levelCompleted = exercises.every((_, exerciseIdx) => levelProgress[exerciseIdx]?.completed);
      const levelStarAchieved = exercises.every((_, exerciseIdx) => levelProgress[exerciseIdx]?.starAchieved);
  
      // Set colors and filters based on progress
      const strokeColor = levelCompleted ? "#FFC935" : "#AFE5FF";
      const WirestrokeColor = levelCompleted ? "#FFC300" : "#70A187";
      const filterId = levelCompleted ? "filter-complete" : "filter-incomplete";
  
      // Update SVG elements for each level
      const levelGroup = document.querySelector(`#beginner-circuitboard #Level-${levelNumber}`);
      if (levelGroup && levelNumber !== 5) {
        const rectGroup = levelGroup.querySelector(`g[id^='Rectangle']`);
        if (rectGroup) {
          const filterExists = document.querySelector(`#${filterId}`);
          if (filterExists) {
            rectGroup.setAttribute("filter", `url(#${filterId})`);
          } else {
            console.error(`Filter #${filterId} not found in DOM`);
          }
          const rectElement = rectGroup.querySelector("rect:last-of-type");
          if (rectElement) {
            rectElement.setAttribute("stroke", strokeColor);
          }
        }
  
        const num = levelGroup.querySelector(`path[id='${levelNumber}']`);
        if (num) {
          num.setAttribute("fill", strokeColor);
        }
  
        const wirePaths = document.querySelectorAll(`#beginner-circuitboard path[id^='Level-${levelNumber}-']`);
        wirePaths.forEach(path => {
          path.setAttribute("stroke", WirestrokeColor);
        });
  
        const bonusPaths = document.querySelectorAll(`#beginner-circuitboard #Level-${levelNumber}-Bonus path`);
        bonusPaths.forEach(path => {
          path.setAttribute("stroke", WirestrokeColor);
        });
  
        const additionalPaths = document.querySelectorAll(`#beginner-circuitboard path[id*='Level-${levelNumber}-']`);
        additionalPaths.forEach(path => {
          if (path.tagName === 'path') {
            path.setAttribute("stroke", WirestrokeColor);
          }
        });
      }
  
      // Update the star element based on star achievement
      const starElement = document.getElementById(`Star-${levelNumber}`);
      if (starElement) {
        const starFilter = levelStarAchieved ? `url(#filter${6 + levelIdx}_d_450_270)` : `url(#filter${11 + levelIdx}_d_450_270)`;
        const starFill = levelStarAchieved ? "#FFC300" : "#565656";
        const starStroke = levelStarAchieved ? "#FFB020" : "#3B3B3B";
        const starPath = starElement.querySelector("path:first-of-type");
        const starOutline = starElement.querySelector("path:last-of-type");
  
        if (starPath) {
          starPath.setAttribute("fill", starFill);
        }
        if (starOutline) {
          starOutline.setAttribute("stroke", starStroke);
        }
        starElement.setAttribute("filter", starFilter);
      }
    });
  
    // Handle bonus level visibility based on progress
    const bonusLevelGroup = document.querySelector("#beginner-circuitboard #Bonus-Level");
    if (bonusLevelGroup) {
      const radialGradientCircle = bonusLevelGroup.querySelector("circle");
      if (radialGradientCircle) {
        const allLevelsCompleted = levels.slice(0, 3).every((_, idx) => {
          const levelProgress = userProgress[idx] || {};
          const exercises = levels[idx].exercises || [];
          return exercises.every((_, exerciseIdx) => levelProgress[exerciseIdx]?.completed);
        });
  
        if (allLevelsCompleted) {
          radialGradientCircle.style.display = "block";
          bonusLevelGroup.classList.add("clickable");
          bonusLevelGroup.addEventListener("click", () => goToLevel(4));
        } else {
          radialGradientCircle.style.display = "none";
          bonusLevelGroup.classList.remove("clickable");
          bonusLevelGroup.removeEventListener("click", () => goToLevel(4));
        }
      }
    }
  }, [userProgress, levels, goToLevel]);
  

// Fetch user progress from Firestore
useEffect(() => {
  const fetchUserProgress = async () => {
    if (currentUser) {
      const userRef = doc(db, 'users', currentUser.uid);
      console.log('Fetching user progress...');
      console.log('User:', currentUser.uid);
      console.log('UserRef:', userRef);
      try {
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const userData = userSnap.data();
          setUserProgress(userData.progress || {});
        } else {
          console.log('No such document! Creating a new user document.');
          await setDoc(userRef, { progress: {} });
          setUserProgress({});
        }
      } catch (error) {
        console.error('Error fetching user progress:', error.message);
      }
    }
  };

  fetchUserProgress();
}, [currentUser]);

// Update the level status when both user progress and SVG are loaded
useEffect(() => {
  if (userProgress && isSVGLoaded) {
    updateLevelStatus();
  }
}, [userProgress, isSVGLoaded, updateLevelStatus]);

  // UseEffect that runs when the SVG is loaded
  const onSVGLoaded = () => {
    updateLevelStatus(); // Ensure initial SVG manipulation after it's loaded
    setIsSVGLoaded(true);
  };

  

  const handleStartLearning = () => {
    const randomLevelIndex = Math.floor(Math.random() * levels.length); // Select random level index
    const selectedLevel = levels[randomLevelIndex];
    const randomExerciseIndex = Math.floor(Math.random() * selectedLevel.exercises.length); // Select random exercise index
    
    // Navigate to the selected random level and exercise
    goToLevel(randomLevelIndex, randomExerciseIndex);
  };


  const toggleChapter = () => {
    setSelectedBoard((prev) => (prev === 0 ? 1 : 0)); // Toggle between chapters
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error.message);
    }
  };
 

  return (
    <>
      <Header />
      <div className="main-section">
        <div className="title-container">
          <h3>{chapters[selectedBoard].title}</h3>
          <p>{chapters[selectedBoard].description}</p>
        </div>
        {/* Circuit Board Section */}
        <div className="circuit-board-wrapper">
          <CircuitBoard
                type={chapters[selectedBoard].type} // Use the "type" from chapters array (beginner or advanced)
                goToLevel={goToLevel}
                onSVGLoaded={updateLevelStatus}
                userProgress={userProgress}
            />
        </div>
      </div>
      <Footer />
    </>
  );
  };




export default HomePage;
