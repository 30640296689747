import React, { useEffect, useRef, useState } from 'react';
import Modal from './Modal'; // Import the custom modal component

const CircuitBoard = ({ type, goToLevel, onSVGLoaded, userProgress }) => {
  const svgRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false); // Modal open state
  const [modalMessage, setModalMessage] = useState(''); // Modal message state

  console.log('User Progress:', userProgress);

  // Function to show the locked level modal
  const showLockedMessage = (levelNumber) => {
    setModalMessage(`Level ${levelNumber} is locked. Complete the previous level to unlock.`);
    setIsModalOpen(true); // Open the modal
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    // Determine the correct SVG file based on the `type` prop
    const svgFile = type === 'beginner'
      ? '/beginner-circuitboard.svg'
      : '/advanced-circuitboard.svg';

    let levelElements = [];

    // Fetch the SVG file content and insert it into the div
    fetch(svgFile)
      .then(response => response.text())
      .then(svgContent => {
        if (svgRef.current) {
          svgRef.current.innerHTML = svgContent;
          levelElements = addClickHandlers(); // Store added event listeners

          // Call the callback to notify the parent that the SVG is loaded
          if (onSVGLoaded) {
            onSVGLoaded();
          }
        }
      })
      .catch(error => {
        console.error('Error loading SVG:', error);
      });

    // Cleanup event listeners when component unmounts or type changes
    return () => {
      removeClickHandlers(levelElements);
    };
  }, [type, onSVGLoaded, userProgress]);

  // Function to add click handlers to levels
  const addClickHandlers = () => {
    const levels = svgRef.current.querySelectorAll("[id^='Level-']");
    const levelElements = [];

    levels.forEach(levelElement => {
      const levelNumber = parseInt(levelElement.id.replace('Level-', ''), 10);
      
      if (!isNaN(levelNumber)) {
        const clickHandler = () => {
          // Check if the previous level is completed
          if (isLevelUnlocked(levelNumber)) {
            goToLevel(levelNumber - 1);
          } else {
            showLockedMessage(levelNumber); // Show the locked message
          }
        };

        levelElement.addEventListener('click', clickHandler);
        levelElements.push({ element: levelElement, handler: clickHandler });
      }
    });

    return levelElements;
  };

// Function to check if a level is unlocked
const isLevelUnlocked = (levelNumber) => {
  if (levelNumber === 1) return true; // Level 1 is always unlocked

  // Define the level dependencies. For example, Level 3 and Level 4 depend on Level 2.
  const levelDependencies = {
    2: 1, // Level 2 depends on Level 1
    3: 2, // Level 3 depends on Level 2
    4: 2  // Level 4 depends on Level 2
  };

  // Get the level this one depends on
  const prerequisiteLevel = levelDependencies[levelNumber];

  // If no prerequisite is defined, assume the level is locked
  if (prerequisiteLevel === undefined) return false;

  // Get the progress for the prerequisite level (levels are 0-indexed)
  const prerequisiteLevelProgress = userProgress[prerequisiteLevel - 1]; // Prerequisite level is 1-indexed

  // Check if all exercises in the prerequisite level are completed
  if (prerequisiteLevelProgress) {
    const allExercisesCompleted = Object.values(prerequisiteLevelProgress).every(
      (exercise) => exercise.completed === true
    );
    return allExercisesCompleted;
  }

  return false; // Default to locked if no progress is found
};



  // Function to remove click handlers when component unmounts or SVG changes
  const removeClickHandlers = (levelElements) => {
    levelElements.forEach(({ element, handler }) => {
      element.removeEventListener('click', handler);
    });
  };
  

  return (
    <div>
      <div ref={svgRef} /> {/* SVG will be rendered here */}

      {/* Custom Modal for showing locked level message */}
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title="Level Locked"
        message={modalMessage}
      />
    </div>
  );
};

export default CircuitBoard;

