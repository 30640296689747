import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { signInWithEmailAndPassword, setPersistence, browserLocalPersistence } from 'firebase/auth';
import './Auth.css';
import GoogleSignInButton from './GoogleSignInButton';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(''); // Add error state
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(''); // Reset error
    try {
      await setPersistence(auth, browserLocalPersistence);
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/learn');
    } catch (error) {
      console.error('Error logging in:', error.message);
      setError(error.message); // Set error message
    }
  };

  return (
    <div className="auth-container">
      {/* Close button */}
      <i className="fas fa-times close-icon" onClick={() => navigate('/')}></i>
      <h2>Login</h2>
      <form onSubmit={handleLogin}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Login</button>
      </form>
      <p>or</p>
      <GoogleSignInButton />
      {error && <p className="error-message">{error}</p>} {/* Display error message */}
      <p>
        Don't have an account?{' '}
        <span onClick={() => navigate('/signup')}>Sign Up</span>
      </p>
      <p>
        Forgot your password?{' '}
        <span onClick={() => navigate('/reset-password')}>Reset Password</span>
      </p>
    </div>
  );
};
export default Login;
