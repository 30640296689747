import React, { useState, useEffect } from 'react';
import { useDrag } from 'react-dnd';
import './DraggableItem.css';

const DraggableItem = ({ item, reset, remainingCount,placeInNextAvailableCell,source,  handleRemove, handleEditResistor,handleItemDragged,onDragStart,animate }) => {
  const { type, connections, svgPath, id, rotation,resistanceValue } = item; // Ensure id is destructured from item
  
  const animationClass = animate && remainingCount > 0 ? 'draggable-item-animate' : '';

  // Determine whether to show the item count
  const showItemCount = Number.isFinite(remainingCount) && remainingCount > 1;

  const handleTap = () => {
    if (remainingCount > 0) {
      placeInNextAvailableCell({ ...item, rotation }); 
    }
  };

  const [{ isDragging }, drag] = useDrag({
    type: source === 'catalog' ? 'CATALOG_ITEM' : 'ITEM',
    item: () => {
      if (onDragStart) onDragStart();
      return { type, connections, svgPath, id, rotation, resistanceValue, source };
    },
    canDrag: () => {
      const canDrag = remainingCount > 0;
      if (canDrag) {
        console.log(`COUNT ${type} (${id}): ${remainingCount} remaining`);
      }
      return canDrag;
    },
    end: (draggedItem, monitor) => {
      if (!monitor.didDrop()) {
        // The item was dropped outside of any drop target
        if (source === 'inventory') {
          // Handle item removal when dragged off inventory
          handleItemDragged(draggedItem, 'delete');
        }
      } else {
        const dropResult = monitor.getDropResult();
        if (source === 'inventory') {
          if (dropResult && !dropResult.rejected) {
            // The item was successfully dropped onto a valid target
            handleItemDragged(draggedItem, 'move');
          }
        }
      }
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  useEffect(() => {
    if (reset) {
      console.log(`COUNT ${type} (${id}): reset`);
    }
  }, [reset]);


  const itemStyle = {
    boxShadow: remainingCount === 0 ? '0 -5px #AFE5FF' : '',
    backgroundColor: remainingCount === 0 ? '#F5F5F5' : '',
    cursor: remainingCount === 0 ? 'not-allowed' : '',
    transform: remainingCount === 0 ? 'translateY(5px)' : '',
  };

  return (
    <div
    className={`draggable-item ${animationClass} ${isDragging ? 'dragged' : ''}`}
      ref={drag}
      style={itemStyle}
      onClick={handleTap}
    >
      {remainingCount > 0 && (
        <>
          <img
            src={svgPath}
            alt={`${type}`}
            style={{
              transform: `rotate(${rotation}deg)`,
              transition: 'transform 0.2s ease',
            }}
          />

          {showItemCount && (
            <div className="item-count">x{remainingCount}</div>
          )}

          {/* Conditionally render the resistance value for resistors */}
          {type === 'resistor' && (
            <div className="resistance-value">
              {resistanceValue}Ω
            </div>
          )}

          {/* Render remove button if handleRemove is provided */}
          {/* {handleRemove && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleRemove();
              }}
              className="remove-button"
            >
              <i className="fas fa-times"></i>
            </button>
          )} */}

          {/* Render edit button for resistors in inventory */}
          {type === 'resistor' && handleEditResistor && (
            <div
              className="edit-icon"
              onClick={(e) => {
                e.stopPropagation();
                handleEditResistor(item, 'inventory');
              }}
              onTouchEnd={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleEditResistor(item, 'inventory');
              }}
            >
              <i className="fas fa-pen"></i>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DraggableItem;
