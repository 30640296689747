// src/components/ExplorePage.js
import React, { useEffect, useState, useContext } from 'react';
import { db } from '../firebase';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './ExplorePage.css';

import Header from './Header';
import Footer from './Footer';

import { AuthContext } from '../contexts/AuthContext';

const ExplorePage = () => {
  const { currentUser } = useContext(AuthContext);

  const [levels, setLevels] = useState([]);
  const navigate = useNavigate();

  const [filterTags, setFilterTags] = useState([]);
  const [availableTags, setAvailableTags] = useState([]);  

  useEffect(() => {
    const fetchLevels = async () => {
      const levelsCollection = collection(db, 'customLevels');
      const levelsQuery = query(levelsCollection, orderBy('createdAt', 'desc'));
      const levelDocs = await getDocs(levelsQuery);
      const levelsData = levelDocs.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setLevels(levelsData);
  
      // Extract available tags
      const allTagsSet = new Set();
      levelsData.forEach((level) => {
        if (level.tags) {
          level.tags.forEach((tag) => allTagsSet.add(tag));
        }
      });
      setAvailableTags(Array.from(allTagsSet));
    };
  
    fetchLevels();
  }, []);
  
  const toggleFilterTag = (tag) => {
    if (filterTags.includes(tag)) {
      setFilterTags(filterTags.filter((t) => t !== tag));
    } else {
      setFilterTags([...filterTags, tag]);
    }
  };

  const filteredLevels = levels.filter((level) => {
    if (filterTags.length === 0) return true; // No filters applied
    if (!level.tags) return false;
    return filterTags.every((tag) => level.tags.includes(tag));
  });
  
  
  const handlePlayLevel = (level) => {
    navigate(`/custom-level/${level.id}`);
  };

  const handleCreateLevel = () => {
    navigate('/level-creator');
  };

  return (
    <>
      <Header />
      <div className="explore-page">
        {/* Title Container */}
        <div className="title-container">
          <h3>Community Levels</h3>
          <p>Make your own level or play someone else's!</p>
        </div>
        {/* Create Level Button */}
            <button className="create-level-button" onClick={handleCreateLevel}>
              Create New Level
            </button>
<br></br>
{availableTags.length > 0 && (
          <div className="filter-bar">
            <p className="filter-bar-p" >Filter by tags:</p>
            <div className="filter-tags">
              {availableTags.map((tag, index) => (
                <button
                  key={index}
                  className={`filter-tag-button ${filterTags.includes(tag) ? 'active' : ''}`}
                  onClick={() => toggleFilterTag(tag)}
                >
                  {tag}
                </button>
              ))}
            </div>
          </div>
        )}

        {/* Level List */}
        <div className="level-list">
          {filteredLevels.map(level => (
            <div key={level.id} className="level-item">
              <div className="level-info">
                <h3 className="level-name">{level.name}</h3>
                <p className="level-creator">By: {level.creatorName}</p>
                <p className="level-plays">Plays: {level.playCount} times</p>
                {level.tags && level.tags.length > 0 && (
                  <div className="level-tags">
                    {level.tags.map((tag, index) => (
                      <span key={index} className="level-tag">
                        {tag}
                      </span>
                    ))}
                  </div>
                )}
              </div>
              <button className="play-level-button" onClick={() => handlePlayLevel(level)}>
                Play Level
              </button>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ExplorePage;
