import React, { useState } from 'react';
import {
  GoogleAuthProvider,
  signInWithPopup,
  setPersistence,
  browserLocalPersistence,
  signInWithRedirect,
} from 'firebase/auth';
import googleLogo from '../assets/GoogleButton.png';
import './GoogleSignInButton.css';

import { auth, db } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { updateProfile } from 'firebase/auth';

const GoogleSignInButton = () => {
    const navigate = useNavigate();
    const [error, setError] = useState('');

  const handleGoogleSignIn = async () => {
    try {
      await setPersistence(auth, browserLocalPersistence);
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Check if user document exists in Firestore
      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);

      if (!userDoc.exists()) {
        // Create a new user document
        await setDoc(userRef, {
          email: user.email,
          progress: {},
          points: 0,
          name: user.displayName || '',
        });
        // Optionally, redirect to a welcome page
        // navigate('/welcome');
      } 
      //   else {
      //     // Redirect to the learn page
      //     navigate('/learn');
      //   }
      // Update the user's display name in Firebase Auth
      if (user.displayName) {
      await updateProfile(user, { displayName: user.displayName });
      }
      navigate('/learn');
    } catch (error) {
      if (error.code === 'auth/popup-blocked') {
        await setPersistence(auth, browserLocalPersistence);
        const provider = new GoogleAuthProvider();
        const result = await signInWithPopup(auth, provider);
        const user = result.user;
  
        // Check if user document exists in Firestore
        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);
  
        if (!userDoc.exists()) {
          // Create a new user document
          await setDoc(userRef, {
            email: user.email,
            progress: {},
            points: 0,
            name: user.displayName || '',
          });
        } 
        if (user.displayName) {
        await updateProfile(user, { displayName: user.displayName });
        }
        navigate('/learn');
        
      } else {
        console.error('Error with Google Sign-In:', error.message);
        setError(error.message);
      }
      
    }
  };


  return (
    <div>
      <button className="google-button" onClick={handleGoogleSignIn}>
        <img src={googleLogo} alt="Google Logo" />
        Continue with Google
      </button>
      {error && <p className="error-message">{error}</p>} {/* Display error message */}
    </div>
  );
};

export default GoogleSignInButton;
