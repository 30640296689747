// src/components/Header.js
import React, { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import './Header.css';

const Header = () => {
  const { userData } = useContext(AuthContext);
  const points = userData?.points || 0;

  console.log('Header userData:', userData);

  return (
    <div className="header-container">
      <div className="header-content">
        <h1 className="logo">playmicro</h1>
        <div className="points-container">
          <i className="fas fa-bolt" style={{ color: '#FFB020' }}></i>
          <span className="points" style={{ color: '#FFC935' }}>{points}</span>
        </div>
      </div>
    </div>
  );
};

export default Header;
