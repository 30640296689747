// src/components/Overlay.js
import React, { useState,useEffect, useRef } from 'react';
import './Overlay.css';

import redLightBulbOn from '../assets/red-light-bulb-on.svg';
import redLightBulbOff from '../assets/red-light-bulb-off.svg';
import redLightBulbDim from '../assets/red-light-bulb-dim.svg';
import redLightBulbExploded from '../assets/red-light-bulb-exploded.svg';

import yellowLightBulbOn from '../assets/yellow-light-bulb-on.svg';
import yellowLightBulbOff from '../assets/yellow-light-bulb-off.svg';
import yellowLightBulbDim from '../assets/yellow-light-bulb-dim.svg';
import yellowLightBulbExploded from '../assets/yellow-light-bulb-exploded.svg';

import greenLightBulbOn from '../assets/green-light-bulb-on.svg';
import greenLightBulbOff from '../assets/green-light-bulb-off.svg';
import greenLightBulbDim from '../assets/green-light-bulb-dim.svg';
import greenLightBulbExploded from '../assets/green-light-bulb-exploded.svg';

import overlayStarOn from '../assets/overlay-star-on.svg';
import overlayStarOff from '../assets/overlay-star-off.svg';

const Overlay = ({ resultMessage, onContinue, onTryAgain, isStarConnected, goal, bulbStatus,exerciseTags,educationalMessages,onLevelComplete,errorMessage,onClose,contiuneResultMessage  }) => {
  console.log("Overlay");
  const hasCompletedLevel = useRef(false);
  const [isVisible, setIsVisible] = useState(true); // State to control visibility
  const [showDetailedHints, setShowDetailedHints] = useState(false);

  // Ensure goal is properly defined before doing any operation on it
  const allBulbsMatch = goal && Array.isArray(goal) 
    ? goal.every(({ type, status }) => bulbStatus[type] === status) 
    : false;

  useEffect(() => {
    if (goal && allBulbsMatch && !hasCompletedLevel.current) {
      onLevelComplete(isStarConnected);
      hasCompletedLevel.current = true;
    }
  }, [allBulbsMatch, isStarConnected, onLevelComplete, goal]);  // Add goal as a dependency

  // Function to handle closing the overlay
  const handleCloseOverlayError = () => {
    setIsVisible(false);
    setTimeout(() => {
      if (onClose) {
        onClose();
      }
    }, 200); // Match the duration of the slide-down animation
  };
    
    // If errorMessage is provided, display the error overlay
    if (errorMessage) {
      console.log(errorMessage);
      return (
        <div
          className={`overlay ${isVisible ? 'slide-in' : 'slide-out'}`}
          style={{ backgroundColor: '#FFF1C5' }}
        >
          <div className="overlay-content">
            <h2 className="tips-h2" style={{ color: '#FFA500' }}>Error</h2>
            <p className="educational-message failure">{errorMessage}</p>
          </div>
          <div className="overlay-button-container">
            <button className="try-again-button" onClick={handleCloseOverlayError}>
              Close
            </button>
          </div>
        </div>
      );
    }
      // If there's a resultMessage, display success overlay
  if (contiuneResultMessage) {
    return (
      <div
        className={`overlay ${isVisible ? 'slide-in' : 'slide-out'}`}
        style={{ backgroundColor: '#DEFFB9' }} // Greenish background for success
      >
        <div className="overlay-content">
          <h2 className="tips-h2" style={{ color: '#5BA61C' }}>Success!</h2>
          <p className="educational-message success">{contiuneResultMessage}</p>
        </div>
        <div className="overlay-button-container">
          <button className="continue-button" onClick={onContinue}>
            Continue
          </button>
        </div>
      </div>
    );
  }



  const getAutomatedFeedback = () => {
    // Group bulbs by their status, but only include those that are in the goal
    const bulbStates = {};
  
    goal.forEach(({ type, status }) => {
      const bulbColor = type.split('-')[0]; // Extract the color (red, green, etc.)
      const currentStatus = bulbStatus[type];
  
      // Only include bulbs that are part of the goal and have the wrong status
      if (currentStatus !== status) {
        if (!bulbStates[currentStatus]) {
          bulbStates[currentStatus] = [];
        }
  
        // Add the bulb's color to the corresponding state group
        bulbStates[currentStatus].push(bulbColor);
      }
    });
  
    // Generate feedback based on the grouped bulb states
    const feedbackMessages = Object.keys(bulbStates).map(status => {
      const bulbs = bulbStates[status];
      const bulbsText = bulbs.length === 1 ? "bulb" : "bulbs"; // Handle singular/plural
  
      let message = '';
  
      if (status === "OFF") {
        message = `The ${bulbs.join(' and ')} ${bulbsText} ${bulbs.length === 1 ? 'is' : 'are'} off because there is no current flowing through ${bulbs.length === 1 ? 'it' : 'them'}. Ensure all components are connected in a closed loop.`;
      } else if (status === "EXPLODED") {
        message = `The ${bulbs.join(' and ')} ${bulbsText} ${bulbs.length === 1 ? 'has' : 'have'} exploded due to too much current. Try adding a resistor to reduce the current.`;
      } else if (status === "DIM") {
        message = `The ${bulbs.join(' and ')} ${bulbsText} ${bulbs.length === 1 ? 'is' : 'are'} dim because the current is too low. Check if the resistors are limiting the current too much.`;
      }
  
      // Add additional hints based on tags
      if (exerciseTags.includes("parallel")) {
        message += " Remember, in parallel circuits, the current splits at junctions.";
      } else if (exerciseTags.includes("switch")) {
        message += " Check if all switches are closed to allow current to flow.";
      }
  
      return message;
    });
  
    return feedbackMessages;
  };
  

  const getTips = () => {
    return goal
      .filter(({ type, status }) => bulbStatus[type] !== status)
      .map(({ type, status }) => {
        let imageUrlCurrent = getBulbImage(bulbStatus[type], type.split('-')[0]);
        let imageUrlTarget = getBulbImage(status, type.split('-')[0]);
        let message = `The ${type.replace('-', ' ')} is currently ${bulbStatus[type]} but should be ${status}.`;
  
        return {
          type,
          message,
          imageUrlCurrent,
          imageUrlTarget,
        };
      });
  };
  
  const getBulbImage = (status, color) => {
    switch (color) {
      case 'red':
        return status === "ON" 
          ? redLightBulbOn 
          : status === "DIM" 
          ? redLightBulbDim 
          : status === "EXPLODED" 
          ? redLightBulbExploded 
          : redLightBulbOff;
      case 'yellow':
        return status === "ON" 
          ? yellowLightBulbOn 
          : status === "DIM" 
          ? yellowLightBulbDim 
          : status === "EXPLODED" 
          ? yellowLightBulbExploded 
          : yellowLightBulbOff;
      case 'green':
        return status === "ON" 
          ? greenLightBulbOn 
          : status === "DIM" 
          ? greenLightBulbDim 
          : status === "EXPLODED" 
          ? greenLightBulbExploded 
          : greenLightBulbOff;
      default:
        return null;
    }
  };
  




  // Control slide up/down animation
  const handleCloseOverlay = () => {
    setIsVisible(false); // Triggers slide-down animation
    setTimeout(() => {
      onTryAgain(); // Call the try again or continue after the animation
    }, 200); // Match the duration of the slide-down animation
  };



  const toggleDetailedHints = () => {
    setShowDetailedHints(!showDetailedHints);
  };

  return (
    <div className={`overlay ${isVisible ? 'slide-in' : 'slide-out'}`} style={{ backgroundColor: allBulbsMatch ? '#DEFFB9' : '#FFF1C5' }}>
      <div className="overlay-content">
        
        {allBulbsMatch ? (
          <>
          <div className="star-text-container">
            <img src={isStarConnected ? overlayStarOn : overlayStarOff} alt="Star Status" className="star-image" />
            <h2 className="tips-h2" style={{ color: "#5BA61C" }}>Well done!</h2>
          </div>
          <p className="educational-message success">{educationalMessages.success}</p>
        </>
        ) : (
          <>
          <h2 className="tips-h2" style={{ color: '#FFA500' }}>
            That's not quite right
          </h2>
          <div className="tips-container">
            {getAutomatedFeedback().map((message, index) => (
              <div key={index} className="tip-item">
                
                <p className="educational-message failure">
                  {educationalMessages.failure}
                </p>
                <p className="educational-message failure">{message}</p>
              </div>
            ))}

          </div>
          {getTips().map((tip, index) => (
          <div className="tip-image-container">
            <img src={tip.imageUrlCurrent} alt="Current bulb state" className="tip-image" />
            <span className="arrow-icon" style={{color: '#FFA500'}}>→</span> {/* Add an arrow icon or image here */}
            <img src={tip.imageUrlTarget} alt="Target bulb state" className="tip-image" />
          </div>
          ))}
        </>
               
          // <>
          //   <h2 className="tips-h2" style={{color:"#FFA500"}}>That's not right</h2>
          //   <div className="tips-container">
          //     {getTips().map((tip, index) => (
          //       <div key={index} className="tip-item">
          //       <p className="tip-message">{tip.message}</p>
          //       <div className="tip-image-container">
          //         <img src={tip.imageUrlCurrent} alt="Current bulb state" className="tip-image" />
          //         <span className="arrow-icon" style={{color: '#FFA500'}}>→</span> {/* Add an arrow icon or image here */}
          //         <img src={tip.imageUrlTarget} alt="Target bulb state" className="tip-image" />
          //       </div>
          //       </div>
          //     ))}
          //   </div>
          // </>
        )}
      </div>
      <div className="overlay-button-container">
        {allBulbsMatch ? (
          <button className="continue-button" onClick={onContinue}>
          Continue
        </button>
        ) : <button className="try-again-button" onClick={onTryAgain}>
        Try Again
      </button>}
      </div>
    </div>
  );
};

export default Overlay;
