// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SchematicQuestion from './components/schematicQuestion/schematicQuestion';
import HomePage from './components/HomePage'; // Learning Page
import Level from './components/Level';
import { LevelProvider } from './contexts/LevelContext';
import MainHomePage from './components/MainHomePage'; // New Main Home Page

import Login from './components/Login';
import SignUp from './components/SignUp';
import PrivateRoute from './components/PrivateRoute'; // Import PrivateRoute
import AccountPage from './components/AccountPage';
import LeaderboardPage from './components/LeaderboardPage';
import ResetPassword from './components/ResetPassword';

import LevelCreator from './components/LevelCreator';
import ExplorePage from './components/ExplorePage';

import { AuthProvider } from './contexts/AuthContext';

const App = () => {
  return (
    <AuthProvider>
    <LevelProvider>
      
        <Router>
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<MainHomePage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/reset-password" element={<ResetPassword />} />

            {/* Private Routes */}
            <Route
              path="/learn"
              element={
                <PrivateRoute>
                  <HomePage />
                </PrivateRoute>
              }
            />
            <Route
              path="/account"
              element={
                <PrivateRoute>
                  <AccountPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/leaderboard"
              element={
                <PrivateRoute>
                  <LeaderboardPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/level/:levelIndex/exercise/:exerciseIndex"
              element={
                <PrivateRoute>
                  <Level />
                </PrivateRoute>
              }
            />

            <Route path="/custom-level/:levelId" element={<Level isCustomLevel={true} />} />
            <Route path="/level/test" element={<Level />} />
            <Route path="/level-creator" element={<LevelCreator />} />
            <Route path="/explore" element={<ExplorePage />} />

            <Route
              path="/schematic-question/:levelId"
              element={
                <PrivateRoute>
                  <SchematicQuestion />
                </PrivateRoute>
              }
            />
          </Routes>
        </Router>
    </LevelProvider>
    </AuthProvider>
  );
};

export default App;
