// src/contexts/LevelContext.js
import React, { createContext, useState } from 'react';

export const LevelContext = createContext();

export const LevelProvider = ({ children }) => {
  const [levelCompletion, setLevelCompletion] = useState(
    Array(5).fill().map(() => ({ completed: false, starAchieved: false }))
  );

  const [levelCompletionAdv, setLevelCompletionAdv] = useState(
    Array(5).fill().map(() => ({ completed: false }))
  );

  return (
    <LevelContext.Provider value={{ 
      levelCompletion, 
      setLevelCompletion,
      levelCompletionAdv,
      setLevelCompletionAdv
    }}>
      {children}
    </LevelContext.Provider>
  );
};
