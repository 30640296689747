// src/components/SignUp.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase'; // Adjust the path as needed
import { createUserWithEmailAndPassword, setPersistence, browserLocalPersistence,AuthErrorCodes} from 'firebase/auth';
import { setDoc, doc } from 'firebase/firestore';
import './Auth.css';
import GoogleSignInButton from './GoogleSignInButton';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  // Add any other fields you require (e.g., username)
  const [error, setError] = useState(''); // Add error state
  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      await setPersistence(auth, browserLocalPersistence);

      // Trim email to remove leading/trailing spaces
      const trimmedEmail  = email.trim();

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        trimmedEmail,
        password
      );
      const user = userCredential.user;

      // Store additional user data in Firestore
      await setDoc(doc(db, 'users', user.uid), {
        email: user.email,
        progress: {}, // Initialize progress
        points: 0, // Initialize points to 0
        name: name, 
      });

      navigate('/learn'); // Navigate to the account-specific learn page
    } catch (error) {
      console.error('Error signing up:', error);
      let errorMessage = 'An error occurred during sign up. Please try again.';
    
      switch (error.code) {
        case AuthErrorCodes.INVALID_EMAIL:
          errorMessage = 'The email address is not valid.';
          break;
        case AuthErrorCodes.EMAIL_EXISTS:
          errorMessage = 'An account with this email already exists.';
          break;
        case AuthErrorCodes.WEAK_PASSWORD:
          errorMessage = 'The password is too weak. It must be at least 6 characters.';
          break;
        // Add more cases as needed
        default:
          errorMessage = error.message;
          break;
      }
    
      setError(errorMessage);
    }
  };

  return (
    <div className="auth-container">
      <i className="fas fa-times close-icon" onClick={() => navigate('/')}></i>
      <h2>Sign Up</h2>
      <form onSubmit={handleSignUp}>
        {/* Add any additional fields here */}
        <input
          type="name"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password (6+ characters)"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Sign Up</button>
      </form>
      <p>or</p>
      <GoogleSignInButton />
      {error && <p className="error-message">{error}</p>} {/* Display error message */}
      <p>
        Already have an account?{' '}
        <span onClick={() => navigate('/login')}>Login</span>
      </p>
    </div>
  );
};

export default SignUp;
