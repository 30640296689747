import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateTo = (path) => {
    navigate(path);
  };

  const getButtonStyle = (path) => {
    return location.pathname === path
      ? {
          backgroundColor: '#FFC300',
          borderColor: '#FFB020',
          boxShadow: '0 5px #FFB020',
        }
      : {};
  };

  return (
  <div className="footer-container">
    <div className="footer-content">
        <button
          className="footer-button"
          style={getButtonStyle('/leaderboard')}
          onClick={() => navigateTo('/leaderboard')}
        >
          <i className="fas fa-trophy"></i>
        </button>
        <button
          className="footer-button"
          style={getButtonStyle('/learn')}
          onClick={() => navigateTo('/learn')}
        >
          <i className="fas fa-microchip"></i>
        </button>
        <button
          className="footer-button"
          style={getButtonStyle('/explore')}
          onClick={() => navigateTo('/explore')}
        >
          <i className="fas fa-compass"></i>
        </button>
        <button
          className="footer-button"
          style={getButtonStyle('/account')}
          onClick={() => navigateTo('/account')}
        >
          <i className="fas fa-user"></i>
        </button>
      </div>
    </div>
  );
};

export default Footer;
