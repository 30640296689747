import redLightBulbOn from '../assets/red-light-bulb-on.svg';
import redLightBulbOff from '../assets/red-light-bulb-off.svg';
import yellowLightBulbOn from '../assets/yellow-light-bulb-on.svg';
import yellowLightBulbOff from '../assets/yellow-light-bulb-off.svg';
import greenLightBulbOn from '../assets/green-light-bulb-on.svg';
import greenLightBulbOff from '../assets/green-light-bulb-off.svg';

import straightWire from '../assets/straight-wire.svg';
import block from '../assets/block.svg';
import tWire from '../assets/t-wire.svg';
import redLightBulb from '../assets/red-light-bulb.svg';
import yellowLightBulb from '../assets/yellow-light-bulb.svg';
import greenLightBulb from '../assets/green-light-bulb.svg';
import battery from '../assets/battery.svg';
import star from '../assets/star-off.svg';
import cornerWire from '../assets/corner-wire.svg';
import switchOnImage from '../assets/comp-switch-on.svg';
import switchOffImage from '../assets/comp-switch-off.svg';

import resistor0 from '../assets/comp-resistor-0.svg';
import resistor1 from '../assets/comp-resistor-1.svg';
const resistorImages = [resistor0, resistor1]; 

const componentDictionary = {
    'green-light-bulb': { svgPath: greenLightBulb, connections: { right: { active: true }, bottom: { active: true } },
    resistanceValue: 40,
     },
    'red-light-bulb': { svgPath: redLightBulb, connections: { right: { active: true }, bottom: { active: true } },
    resistanceValue: 40,
   },
    'yellow-light-bulb': { svgPath: yellowLightBulb, connections: { top: { active: true }, bottom: { active: true } },
    resistanceValue: 40,
   },
    'battery': { svgPath: battery, connections: { left: { active: true, polarity: 'positive' }, right: { active: true, polarity: 'negative' } } },
    'straight-wire': { svgPath: straightWire, connections: { top: { active: true }, bottom: { active: true } } },
    'star': { svgPath: star, connections: { left: { active: true }, right: { active: true } } },
    'block': { svgPath: block, connections: {} },
    't-wire': { svgPath: tWire, connections: { right: { active: true }, bottom: { active: true }, left: { active: true } } },
    'corner-wire': { svgPath: cornerWire, connections: { left: { active: true }, bottom: { active: true } } },
    'switch': {
      svgPath: switchOffImage, 
      onConnections: { left: { active: true }, right: { active: true } },
      offConnections: {},
  
      initialize: (defaultState) => {
        const isOn = defaultState === 'ON';
        const connections = isOn ? componentDictionary['switch'].onConnections : componentDictionary['switch'].offConnections;
        const svgPath = isOn ? switchOnImage : switchOffImage;
  
        return { svgPath, connections, isOn }; // Return both the state and connections
      },
    },
    'resistor': {
      svgPath: resistor0, // Provide a default svgPath
      connections: { left: { active: true }, right: { active: true } },
      initialize: (id = 0) => {
        const svgPath = resistorImages[id % resistorImages.length];
        return {
          svgPath,
          connections: { left: { active: true }, right: { active: true } },
        };
      },
      
    },
  };

  export default componentDictionary;