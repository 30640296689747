// src/components/AccountPage.js
import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { auth, db } from '../firebase';
import { updateEmail, updatePassword, updateProfile } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import './AccountPage.css';

const AccountPage = () => {
  const { currentUser, userData } = useContext(AuthContext);
  const [editingField, setEditingField] = useState(null);

  const navigate = useNavigate();

  const handleLogout = async () => {
    await auth.signOut();
    navigate('/login');
  };

  const startEditing = (field) => {
    setEditingField(field);
  };

  const saveChanges = async (field, newValue) => {
    try {
      if (field === 'displayName' || field === 'name') {
        await updateProfile(currentUser, { displayName: newValue });
        const userRef = doc(db, 'users', currentUser.uid);
        await updateDoc(userRef, { name: newValue });
      } else if (field === 'email') {
        await updateEmail(currentUser, newValue);
        const userRef = doc(db, 'users', currentUser.uid);
        await updateDoc(userRef, { email: newValue });
      } else if (field === 'password') {
        await updatePassword(currentUser, newValue);
      }
      setEditingField(null);
    } catch (error) {
      console.error('Error updating user information:', error);
    }
  };

  const cancelEditing = () => {
    setEditingField(null);
  };

  const AccountItem = ({ label, field, value, editingField, onEdit, onSave, onCancel }) => {
    const isEditing = editingField === field;
    const [newValue, setNewValue] = useState(value);

    useEffect(() => {
      if (isEditing) {
        setNewValue(value);
      }
    }, [isEditing, value]);

    return (
      <div className="account-item">
        <div className="account-item-header">
          <span className="field-label">{label}</span>
          {isEditing ? (
            <div className="icon-container">
              <button className="icon-button" onClick={() => onSave(field, newValue)}>
                <i className="fas fa-check-circle" style={{ color: '#7BC625' }}></i>
              </button>
              <button className="icon-button" onClick={onCancel}>
                <i className="fas fa-times-circle" style={{ color: '#E53B3B' }}></i>
              </button>
            </div>
          ) : (
            <button className="edit-button" onClick={() => onEdit(field)}>
              Edit
            </button>
          )}
        </div>
        {isEditing ? (
          <input
            type={field === 'password' ? 'password' : 'text'}
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
            placeholder={`Enter your ${field}`}
          />
        ) : (
          <span className="field-value" style={{ display: 'block', width: '100%' }}>
            {field === 'password' ? '********' : value}
          </span>
        )}
      </div>
    );
  };

  return (
    <>
      <Header />
      <div className="main-section">
        <div className="title-container">
          <h3>Hi {currentUser.displayName || userData?.name || 'User'}</h3>
          <p>Account Settings</p>
        </div>
        <div className="content">
          {/* Name */}
          <AccountItem
            label="Name:"
            field="displayName"
            value={currentUser.displayName || userData?.name || ''}
            editingField={editingField}
            onEdit={startEditing}
            onSave={saveChanges}
            onCancel={cancelEditing}
          />

          {/* Email */}
          <AccountItem
            label="Email:"
            field="email"
            value={currentUser.email}
            editingField={editingField}
            onEdit={startEditing}
            onSave={saveChanges}
            onCancel={cancelEditing}
          />

          {/* Password */}
          <AccountItem
            label="Password:"
            field="password"
            value="" // Keep empty or use masked display
            editingField={editingField}
            onEdit={startEditing}
            onSave={saveChanges}
            onCancel={cancelEditing}
          />

          {/* Logout */}
          <div className="account-item logout-item">
            <div className="account-item-header">
              <span className="field-label logout-label" onClick={handleLogout}>
                Logout
              </span>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AccountPage;
