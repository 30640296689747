// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDtQoewc19DY1IOZ_EjW3mR4D-9ge02rG8",
  authDomain: "playmicrolabs-8c3b6.firebaseapp.com",
  projectId: "playmicrolabs-8c3b6",
  storageBucket: "playmicrolabs-8c3b6.appspot.com",
  messagingSenderId: "1008149225078",
  appId: "1:1008149225078:web:248ead411d1ad04fd44957",
  measurementId: "G-KC0NSXL19R"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const auth = getAuth(app);
const db = getFirestore(app); // Initialize Firestore

export { auth, db };