import React from 'react';
import './Header.css';
import { useParams, useLocation, useNavigate } from "react-router-dom"; // Import from react-router-dom

const Header = ({ progress }) => {
  const navigate = useNavigate();
  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <header className="app-header">
      <button className="exit-button" onClick={refreshPage}><i className="fas fa-undo" style={{fontSize:'24px'}}></i> {/* Font Awesome times icon */}</button>
      <div className="progress-bar-container">
        <div className="progress-bar" style={{ width: `${progress}%` }}></div>
      </div>
      <button className="exit-button" onClick={() => navigate('/')}>
          <i class="fas fa-times"></i>
          </button>
    </header>
  );
};

export default Header;
