import React from 'react';
import './CheckButton.css';


const CheckButton = ({ onCheck, onNextExercise }) => {
  return (
    <button className="test-button" onClick={onCheck}>
      Check Answer
    </button>
  );
};

export default CheckButton;
