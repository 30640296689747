import React, { useState } from 'react';
import './ResistorValuePopup.css';

const ResistorValuePopup = ({ onClose, onSave, initialValue }) => {
  // E12 standard resistor values in ohms
  const e12ValuesOhms = [100, 120, 150, 180, 220, 270, 330, 390, 470, 560, 680, 820];
  
  // State for unit ('ohms' or 'kiloohms')
  const [unit, setUnit] = useState('ohms');

  // State for selected resistor value
  const [selectedValue, setSelectedValue] = useState(initialValue || 330);
  const [customValue, setCustomValue] = useState('');

  const handleUnitToggle = (selectedUnit) => {
    setUnit(selectedUnit);
  };

  const handleValueSelect = (value) => {
    setSelectedValue(value);
    setCustomValue('');
  };

  const handleCustomValueChange = (e) => {
    setCustomValue(e.target.value);
    setSelectedValue(null);
  };

  const handleSaveChanges = () => {
    let finalValue;
    if (customValue) {
      finalValue = parseFloat(customValue);
    } else if (selectedValue) {
      finalValue = selectedValue;
    } else {
      finalValue = initialValue || 330;
    }

    // Call onSave with the finalValue in ohms regardless of the display unit
    onSave(finalValue);
    onClose();
  };

  return (
    <div className="resistor-value-popup-overlay">
      <div className="resistor-value-popup">
        {/* Title and Close Button */}
        <div className="popup-header">
          <h2>Set Resistor Value</h2>
          <button className="close-button-popup" onClick={onClose}>
            <i className="fas fa-times"></i>
          </button>
        </div>
        {/* Instruction Text */}
        <p className="instruction-text">
          Remember that a larger resistance means less current will flow through – think of a water pipe being constricted!
        </p>
        {/* Resistor Value Buttons */}
        <div className="resistor-values-grid">
          {e12ValuesOhms.map((value) => {
            const displayValue = unit === 'ohms' ? value : (value / 100).toFixed(1);
            const unitSymbol = unit === 'ohms' ? 'Ω' : 'kΩ';
            const isSelected = selectedValue === value && customValue === '';
            return (
              <button
                key={value}
                className={`resistor-value-button ${isSelected ? 'selected' : ''}`}
                onClick={() => handleValueSelect(value)}
              >
                {displayValue}{unitSymbol}
              </button>
            );
          })}
        </div>
        {/* Unit Toggle and Custom Input */}
        <div className="unit-toggle-and-custom-input">
          {/* Unit Toggle */}
          {/* <div className="unit-toggle">
            <button
              className={`unit-button ${unit === 'ohms' ? 'active' : ''}`}
              onClick={() => handleUnitToggle('ohms')}
            >
              Ω
            </button>
            <button
              className={`unit-button ${unit === 'kiloohms' ? 'active' : ''}`}
              onClick={() => handleUnitToggle('kiloohms')}
            >
              kΩ
            </button>
          </div> */}
          
          {/* Custom Value Input */}
          <div className="custom-input-wrapper">
            <input
              type="number"
              className="custom-value-input"
              placeholder="Enter value"
              value={customValue}
              onChange={handleCustomValueChange}
              onFocus={(e) => e.target.select()} 
            />
            <span className="unit-symbol">{unit === 'ohms' ? 'Ω' : 'kΩ'}</span>
          </div>
        </div>
        {/* Save Changes Button */}
        <button className="save-changes-button" onClick={handleSaveChanges}>
          Save Changes
        </button>
      </div>
    </div>
  );
};

export default ResistorValuePopup;
