import React from 'react';
import './Modal.css'; // You'll define the styles here

const Modal = ({ isOpen, onClose, title, message }) => {
  if (!isOpen) return null; // If the modal is not open, render nothing

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>{title}</h2>
        <p>{message}</p>
        <button onClick={onClose} className="modal-close-button">Close</button>
      </div>
    </div>
  );
};

export default Modal;
