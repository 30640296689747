import React from 'react';
import './QuestionComponent.css';

import redLightBulbOn from '../assets/red-light-bulb-on.svg';
import redLightBulbDim from '../assets/red-light-bulb-dim.svg';
import redLightBulbOff from '../assets/red-light-bulb-off.svg';

import yellowLightBulbOn from '../assets/yellow-light-bulb-on.svg';
import yellowLightBulbDim from '../assets/yellow-light-bulb-dim.svg';
import yellowLightBulbOff from '../assets/yellow-light-bulb-off.svg';

import greenLightBulbOn from '../assets/green-light-bulb-on.svg';
import greenLightBulbDim from '../assets/green-light-bulb-dim.svg';
import greenLightBulbOff from '../assets/green-light-bulb-off.svg';

import lightBulbOff from '../assets/light-bulb-off.svg';

const getBulbImage = (type, status) => {
  switch (type) {
    case 'red-light-bulb':
      return status === "ON" ? redLightBulbOn : status === "DIM" ? redLightBulbDim : null;
    case 'yellow-light-bulb':
      return status === "ON" ? yellowLightBulbOn : status === "DIM" ? yellowLightBulbDim : null;
    case 'green-light-bulb':
      return status === "ON" ? greenLightBulbOn : status === "DIM" ? greenLightBulbDim : null;
    default:
      return null; // Do not show anything if the status is OFF or undefined
  }
};

const getBackgroundColor = (type) => {
  switch (type) {
    case 'red-light-bulb':
      return '#F0C6C6'; // Light red
    case 'yellow-light-bulb':
      return '#F7E9B7'; // Light yellow
    case 'green-light-bulb':
      return '#DCEBCB'; // Light green
    default:
      return '#f5f5f5'; // Grey for undefined items
  }
};

const QuestionComponent = ({ goal }) => {
  return (
    <div className="question">
      <h3>
        Create a circuit to match <span className="no-wrap">the bulbs (
        <img src={lightBulbOff} alt="light-bulb-off" className="small-bulb-icon" />
        ) below:</span>
      </h3>
      <div className="bulb-indicators">
        {/* Only display bulbs that are "ON" or "DIM" */}
        {goal.filter(bulb => bulb.status === "ON" || bulb.status === "DIM").map((bulb, index) => (
          <div
            key={index}
            className="bulb-container-goal"
            style={{ backgroundColor: getBackgroundColor(bulb.type) }}
          >
            <img
              className="bulb-goal"
              src={getBulbImage(bulb.type, bulb.status)}
              alt={`${bulb.type}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuestionComponent;
