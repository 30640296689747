// src/components/SettingsPopup.js

import React, { useState } from 'react';
import './SettingsPopup.css';

const SettingsPopup = ({
  onClose,
  onSave,
  initialRows,
  initialCols,
  initialSuccessMessage,
  initialTryAgainMessage,
  initialTags
}) => {
  const [rows, setRows] = useState(initialRows?.toString() || '');
  const [cols, setCols] = useState(initialCols?.toString() || '');
  const [successMessage, setSuccessMessage] = useState(initialSuccessMessage || '');
  const [tryAgainMessage, setTryAgainMessage] = useState(initialTryAgainMessage || '');
  const [tagsInput, setTagsInput] = useState(initialTags || '');
  const predefinedTags = ['Beginner', 'Intermediate', 'Advanced', 'Expert', 'Challenge'];
  const [selectedTags, setSelectedTags] = useState(initialTags ? initialTags.split(',') : []);
  

  const [rowsError, setRowsError] = useState('');
  const [colsError, setColsError] = useState('');

  const handleRowsChange = (e) => {
    const value = e.target.value;
    setRows(value);
    const num = parseInt(value);
    if (value === '') {
      setRowsError('Rows is required.');
    } else if (isNaN(num) || num < 2 || num > 9) {
      setRowsError('Rows must be a number between 2 and 9.');
    } else {
      setRowsError('');
    }
  };

  const handleColsChange = (e) => {
    const value = e.target.value;
    setCols(value);
    const num = parseInt(value);
    if (value === '') {
      setColsError('Columns is required.');
    } else if (isNaN(num) || num < 2 || num > 9) {
      setColsError('Columns must be a number between 2 and 9.');
    } else {
      setColsError('');
    }
  };

  const handleSave = () => {
    let rowsNum = parseInt(rows);
    let colsNum = parseInt(cols);

    // Additional validation in case of unexpected input
    if (isNaN(rowsNum) || rowsNum < 2) rowsNum = 2;
    if (rowsNum > 9) rowsNum = 9;

    if (isNaN(colsNum) || colsNum < 2) colsNum = 2;
    if (colsNum > 9) colsNum = 9;

    const tagsInput = selectedTags.join(',');

    onSave(rowsNum, colsNum, successMessage, tryAgainMessage, tagsInput);
  };

  const isFormValid = !rowsError && !colsError && rows !== '' && cols !== '';

  return (
    <div className="settings-popup-overlay">
      <div className="settings-popup">
        {/* Header */}
        <div className="settings-popup-header">
          <h2>Level Settings</h2>
          <button className="close-button-popup" onClick={onClose}>
            <i className="fas fa-times"></i>
          </button>
        </div>

        {/* Grid Size Section */}
        <div className="grid-size-section">
          <h3>Set Grid Size:</h3>
          <div className="grid-size-inputs">
            <div className="input-group">
              <label htmlFor="rows-input">Rows:</label>
              <input
                id="rows-input"
                type="number"
                min="2"
                max="9"
                value={rows}
                onChange={handleRowsChange}
                onFocus={(e) => e.target.select()} 
              />
              
            </div>
            <div className="input-group">
              <label htmlFor="cols-input">Columns:</label>
              <input
                id="cols-input"
                type="number"
                min="2"
                max="9"
                value={cols}
                onChange={handleColsChange}
                onFocus={(e) => e.target.select()} 
              />
              
            </div>

          </div>
          {rowsError && <div className="error-message">{rowsError}</div>}
          {colsError && <div className="error-message">{colsError}</div>}
        </div>

        {/* Success Message Section */}
        <div className="message-group success-message-group">
          <label htmlFor="success-message">Set Success Message:</label>
          <textarea
            id="success-message"
            placeholder="This will appear when the user gets the level correct. Help explain why it's correct!"
            value={successMessage}
            onChange={(e) => setSuccessMessage(e.target.value)}
          />
        </div>

        {/* Try Again Message Section */}
        <div className="message-group try-again-message-group">
          <label htmlFor="try-again-message">Set Try Again Message:</label>
          <textarea
            id="try-again-message"
            placeholder="This will appear when the user gets the level incorrect. Provide tips and tricks"
            value={tryAgainMessage}
            onChange={(e) => setTryAgainMessage(e.target.value)}
          />
        </div>

        {/* Tags Input Section */}
        <div className="tags-input-section">
          <label>Set Tags:</label>
          <div className="tags-container">
            {predefinedTags.map((tag) => (
              <div
                key={tag}
                className={`tag-item ${selectedTags.includes(tag) ? 'selected' : ''}`}
                onClick={() => {
                  if (selectedTags.includes(tag)) {
                    setSelectedTags(selectedTags.filter(t => t !== tag)); // Remove tag if already selected
                  } else {
                    setSelectedTags([...selectedTags, tag]); // Add tag if not selected
                  }
                }}
              >
                {tag}
              </div>
            ))}
          </div>
        </div>




        {/* Buttons */}
        <div className="settings-popup-buttons">
          <button className="save-button" onClick={handleSave} disabled={!isFormValid}>
            Save
          </button>
          <button className="cancel-button" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default SettingsPopup;
